section.library-hero .container {
    position: relative;
}
section.library-hero .container .not-got-dots {
    transform: rotate(180deg);
    position: absolute;
    right: -2%;
    bottom: -50%;
}

section.library-hero .container .text {
    height: 500px;
    width: 610px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    background-image: url('https://6637851.fs1.hubspotusercontent-na1.net/hubfs/6637851/Api%20Direct%20Version%202%20Resources/ReactApiImg/direct-center.png');
}

div.like-form {
    background-color: #001A21;
    padding: 3rem 2rem;
    width: 380px;
    border-radius: 8px;
}
div.like-form .form-hold input{
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius: 40px;
}

.z-2{
    z-index: 2;
}
.accordion-item {
    background-color: transparent;
    border: none;
}
#filter-button {
    background-color: rgba(0, 26, 33, 1);
    column-gap: 0.5rem;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}
#view-switch {
    background-color: rgba(0, 26, 33, 1);
    width: 185px;
    z-index: 1;
    border: none;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

#filter-button::after{
    display: none;
}

#search-box {
    width: 100%;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    background-color: rgba(0, 26, 33, 1);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
#search-box input {
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    color: #ccc;
    font-family: var(--font-mont);
}
#search-box input::placeholder {
    color: rgba(13, 155, 156, 0.5);
}
#search-box #search-list {
    display: none;
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
    color: #fff;
    border-radius: 5px;
    z-index: 2;
    background-color: rgba(0, 26, 33, 1);
}
#search-box:focus-within #search-list {
    display: block;
}
#search-list .n-it a {
    font-family: var(--font-mont);
}
#search-list .n-it a > div {
    padding-left: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 5px;
}
#search-list .n-it a span.pu {
    font-size: x-small;
}
#search-list .n-it a > div:hover {
    background-color: #078181;
}

.tag-box {
    display: flex;
    gap: .6rem;
}

.tag-box .search-tag {
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s ease;
}
.tag-box .search-tag:hover {
    border-color: var(--primary-3);
}
.tag-box .search-tag.active {
    border-color: var(--primary-3);
}

.flag {
    position: relative;
}
.flag::after {
    position: absolute;
    z-index: 3;
    display: none;
    width: fit-content;
    content: attr(data-title);
    padding: 0.3rem 0.7rem;
    border-radius: 5px;
    top: -120%;
    right: -140%;
    background-color: rgba(2, 45, 51, 1);
    font-family: var(--font-lucida);
    color: #ccc;
}
.flag:hover::after {
    display: block;
}

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 12px;
}
label.reg-f {
    cursor: pointer;
}
label.reg-f input,
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #002F3C;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0px;
    bottom: -2px;
    background-color: #ADADAD;
    -webkit-transition: .4s;
    transition: .4s;
}
input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}
label.reg-f input:checked+ span {
    box-shadow: 2px 2px 4px  #ccc;
    border-radius: 4px;
}
input:checked+.slider:before {
    background-color: #059B9C;
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}

.filter-hold {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.gap-10px {
    gap: 10px;
}
button.master-reset {
    display: flex;
    align-items: center;
}
button.filter-tag-btn {
    background-color: transparent;
    color: #989898;
    outline: none;
    border: none;
    font-size: 12px;
    margin-left: 0.5rem;
    border-radius: 5px;
}
button.filter-tag-btn:hover {
    /* background-color: rgba(2, 45, 51, 1); */
    color: #fff;
}
span.filter-tag-sp {
    max-width: fit-content;
}
label.filter-tag-ch {
    border: 1px solid #fff;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    padding: 5px 12px;
    border-radius: 5px;
    cursor: pointer;
}
input.filter-tag-ip {
    width: 0;
    height: 0;
}
input.filter-tag-ip:hover + label.filter-tag-ch,
input.filter-tag-ip:checked + label.filter-tag-ch {
    border-color: #0D9B9C;
    color: #0D9B9C;
}
input.filter-tag-ip:checked+label.filter-tag-ch {
    background-color: #059B9A26;
    font-weight: 500;
}
.cards.grid {
    column-gap: 1%;
    row-gap: 0.8rem;
    justify-content: center;
}
.cards .card {
    background-color: rgba(0, 26, 33, 1);
    border-radius: 8px;
}
.cards.grid .card {
    width: max(42%, 388px);
    height: 520px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
}

@media (width > 1025px) {
    .cards.grid {
        column-gap: 10px;
        row-gap: 10px;
    }
    .cards.grid .card {
        width: 388px;
    }
}

.cards .card .title-logo {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.cards.grid .card .title-logo {
    justify-content: space-between;
}
.cards .card .info .faded {
    opacity: 25%;
}
.cards .card .title-logo .free-api {
    font-size: 14px;
    color: #ccc;
    font-family: var(--font-mont);
    border: 1px solid #fff;
    padding: 0.2rem 1rem;
    border-radius: 25px;
}
.cards .card .title-logo .logo {
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    overflow: hidden;
    background-color: #ccc;
}

.cards .card .grid-tag-img {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
}
.card .desc {
    font-family: var(--font-lucida);
    color: #ccc;
    font-size: 16px;
    line-height: 16px;
    max-height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.cards.grid .desc {
    margin-top: 1rem;
}
.cards.grid .text-tags {
    padding-top: 0.5rem;
}
.cards.grid .info {
    margin-top: auto;
}
.cards.grid .list-tag-img {
    display: none;
}

/* -------------------------- */

.cards.list {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}
.cards.list .card {
    padding: 1.5rem;
    width: 100%;
    display: flex;
    min-height: 250px;
    column-gap: 1rem;
    flex-direction: row;
    justify-content: space-between;
}
.cards.list .title-logo {
    max-width: 400px;
    flex-direction: row-reverse;
    column-gap: 1.5rem;
    justify-content: left;
}
.cards.list .grid-tag-img {
    display: none;
}
.cards.list .list-tag-img {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    column-gap: 1rem;
}
.cards.list .text-tags {
    padding-top: 1rem;
}
.cards.list .desc-hold {
    width: 40%;
    max-width: 450px;
    margin-top: auto;
    margin-bottom: auto;
}
.cards.list .info {
    margin-top: auto;
    margin-bottom: auto;
}


@media (1025px < width < 1399px) {
    section.library-hero .container .text {
        width: 430px;
    }
}

@media ( 769px < width < 1024.98px) {
    section.library-hero .container .text {
        width: 390px;
    }
}

@media (width < 768.98px) {
    section.library-hero .container .text {
        width: min(100%, 390px);
        height: 350px;
    }
    #view-switch {
        display: none;
    }
}

@media (width < 480.98px) {
    div.like-form {
        width: 100%;
    }
    #filter-button .fsxl-l16 {
        display: none;
    }
    .toggle-text {
        font-size: 12px;
    }
}

.cards .card {
    transition: 0.2s ease;
}
.cards .card:hover {
    background-color: var(--primary-3-25);
}


#filter-button.collapsed #cross-icon {
    display: none;
}
#filter-button[aria-expanded="true"] #filter-icon {
    display: none;
}
