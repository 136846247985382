nav#main-nav {
    width: 100%;
    position: sticky;
    background: #000B0E;
    top: 0;
    z-index: 4;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    /* max-width: 1500px; */
    margin: auto;
}

.navbar-links {
    margin-left: auto;
    display: none;
    margin-right: 1rem;
}

.navbar-links ul {
    list-style: none;
    display: flex;
    column-gap: 1rem;
    justify-content: center;
    margin: 0;
}
.navbar-links ul li {
    width: fit-content;
    text-align: center;
};

@media (width < 768px) {
    nav#main-nav {
        display: flex;
        justify-content: center;
    }
    nav#main-nav .container {
        width: fit-content;
    }
    
}

/* @media (width > 768px) {
    #main-nav .logo {
        margin-left: 4rem;
    }
} */

.sidepanel {
    width: 0;
    position: absolute;
    z-index: 5;
    font-family: var(--font-mont);
    height: 100vh;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
}

.sidepanel .btn-holder {
    padding-top: 2rem;
}

.sidepanel a {
    text-decoration: none;
    font-size: var(--fsm24);
    display: block;
    width: 400px;
    color: #818181 !important;
    padding: 11px 0;
    transition: 0.3s ease;
}

.sidepanel a:hover {
    color: #f1f1f1 !important;
}

.sidepanel .nav-toggle-btn {
    margin-left: calc(100% - 2rem - 40px);
}

@media (min-width: 1025px) {
    .nav-toggle-btn {
        display: none;
    }
    
    .navbar-links {
        display: block;
    }
}

.nav-toggle-btn {
    margin-left: auto;
    font-size: 20px;
    cursor: pointer;
    background-color: #050505;
    color: #eee;
    width: 40px;
    height: 40px;
    border: 1.3px solid #fff;
    border-radius: 50%;
}

.nav-toggle-btn:hover {
    background-color: #ccc;
    color: #000;
}

.sidepanel ul {
    list-style-type: none;
}

.sidepanel .ulScroll {
    min-height: calc(100vh - 4.5rem);
    max-height: calc(100vh - 4.5rem);
    overflow-y: scroll;
    background-color: #000;
    padding-right: 2rem;
}

.sidepanel .ulScroll::-webkit-scrollbar {
    display: none;
}

.sidepanel .ulScroll ul li:not(:last-child) {
    border-bottom: 1px solid #fff;
}