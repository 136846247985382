section.hero {
    position: relative;
    background-color: var(--primary-5);
}

section.hero>.container-fluid {
    background-color: var(--primary-5);
    position: relative;
}

section.hero .browse:hover {
    color: var(--primary-3) !important;
}
section.hero .browse {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    transition: 0.2s ease;
}

section.what .what-title {
    width: 450px;
}

section.what .what-desc {
    width: 570px;
}

.start-browsing {
    border: 1px solid #fff;
    width: fit-content;
    padding: 0.6rem 2.5rem;
    border-radius: 40px;
    transition: 0.3s ease;
    color: #fff !important;
}

.start-browsing:hover {
    color: #000 !important;
    background-color: #ccc;
}

section.probably-ads .container {
    min-height: 400px;
}
.ad-cards {
    position: relative;
}
.ad-cards .ac {
    min-width: 610px;
    min-height: 375px;
    position: absolute;
}
.ad-cards .ac {
    background-color: #ccc;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    outline: 1px solid var(--primary-3);
    border-radius: 5px;
    background-image: url('https://6637851.fs1.hubspotusercontent-na1.net/hubfs/6637851/Api%20Direct%20Version%202%20Resources/Image/Api_directv.1.png');
}
.ad-cards .ac.fore {
    transform: translate(40px, 40px);
}
.ad-cards .ac.back {
    transform: rotate(-4.02deg);
    background-color: #cccccc;
}

section.probably-ads .ad-card-right {
    width: 350px;
}
@media (width > 1024px) {
    section.probably-ads .ad-card-right {
        width: 450px;
    }
    section.probably-ads .ad-card-right p {
        width: 350px;
    }
}

div.not-got-hold {
    position: relative;
}

img.not-got-dots.left {
    position: absolute;
    left: -40px;
    top: -120px;
}

img.not-got-dots.right {
    position: absolute;
    right: -40px;
    top: -120px;
}

img.not-got-dots.bottom {
    position: absolute;
    right: 0;
    bottom: -120px;
    transform: rotate(180deg);
}

div.not-got {
    position: relative;
    border-radius: 32px;
    border: 2px solid #cccccc;
    padding-top: 6rem;
    padding-bottom: 6rem;
    margin-bottom: 6rem;
}

div.not-got .form-hold {
    width: 452px;
}

#ngth {
    max-width: 420px;
}

@media (1024px < width < 1399px) {
    .ad-cards .ac {
        min-width: 380px;
        min-height: 234px;
    }
    section.probably-ads .container {
        min-height: 300px;
    }
}

@media (769px < width < 1023.98px) {
    .ad-cards .ac {
        min-width: 278px;
        min-height: 170px;
    }
    section.probably-ads .container {
        min-height: 200px;
    }
    section.what .what-title,
    section.what .what-desc {
        width: 50%;
    } 
    section.what .start-browsing {
        transform: translateY(20px);
    }
    #ngth {
        width: 310px;
    }
    section.container.not-got-hold {
        padding: 0;
    }
    div.not-got .form-hold {
        width: 340px;
    }
    div.not-got {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    
}

@media (width < 768.98px) {
    .ad-cards .ac {
        min-width: 247px;
        min-height: 141px;
    }
    section.probably-ads .ad-cards {
        max-width: 300px;
    }
    section.probably-ads .container .ad-card-right {
        margin: auto;
    }
    section.probably-ads .container .ad-cards {
        min-height: 200px;
        transform: translateX(30%);
    }
    div.not-got {
        row-gap: 1rem;
    }
    img.not-got-dots {
        display: none;
    }
    section.what .what-title {
        width: 100%;
    }
    section.what .what-title .fsxl40 {
        font-size: 32px;
        line-height: 39px;
    }
    section.what .what-desc{
        width: 100%;
    }
    div.not-got .form-hold {
        width: min(80vw, 380px);
        margin: auto;
    }
    section.what .start-browsing {
        transform: translateY(20px);
    }
    #ngth {
        width: 90%;
    }
}

@media (width < 480.98px) {
    section.probably-ads .container .ad-cards {
        transform: translateX(10%);
    }
    section.hero .browse span {
        display: none;
    }
}
@media (width > 480px) {
    #carousel-hold {
        padding-left: 0;
        padding-right: 0;        
    }
}
.scroller {
    position: relative;
    overflow: hidden;
    width: 20rem;
    height: 1.8ch;
}

@media (width > 100px) {
    .scroller {
        width: 100%;
    }
}

.scroller > span {
    position: absolute;
    top: 0;
    animation: slide 10s infinite;
}
@keyframes slide {
    0% {
      top: 0;
    }
    20% {
      top: -1.2em;
    }
    40% {
      top: -2.4em;
    }
    60% {
      top: -3.6em;
    }
    80% {
        top: -4.8em;
    }
    100% {
        top: 0;
    }
  }