section#api-detail {
    background-image: url('https://6637851.fs1.hubspotusercontent-na1.net/hubfs/6637851/Api%20Direct%20Version%202%20Resources/ReactApiImg/details-hero-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
section#api-detail .detail-hero {
    min-height: 60vh;
    padding-top: 3%;
    color: #ccc;
}

section#api-detail .detail-hero .api-logo {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #ccc;
    display: grid;
    place-items: center;
}
section#api-detail .detail-hero .api-logo img {
    object-fit: cover;
}

section#api-detail .detail-hero .api-title > * {
    padding-top: 1rem;
    font-size: 48px;
    padding-top: 3rem;
    font-weight: 600;
}

section#api-detail .overview-n-form {
    margin-top: 4rem;
    padding-top: 2rem;
}

#api-detail .like-form {
    height: fit-content;
}



section#resource-tab {
    padding-bottom: 3rem;
}

section#resource-tab nav {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

section#resource-tab .nav {
    flex-wrap: nowrap;
}
section#resource-tab  .nav-tabs {
    border-width: 1.5px;
    border-color: transparent;
    max-width: 100vw;
}
section#resource-tab #nav-tab .nav-link {
    /* min-width: 33.33%;
    width: fit-content; */
    font-family: var(--font-mont);
    color: #ccc;
    outline: none;
    border: none;
    background-color: transparent;
    transition: 0.2s ease;
}
section#resource-tab #nav-tab .nav-link.active {
    border-bottom: 2px solid #059B9C;
    font-weight: 500;
}

section#resource-tab .tab-content {
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    border-radius: 5px;
    background-color: var(--primary-5);
    font-family: var(--font-mont);
    color: #ccc;
}


section#resource-tab .tab-content #nav-profile .rs-card {
    margin-bottom: 2rem;
}

section#resource-tab .tab-content #nav-profile .rs-card .header {
    text-align: center;
    padding-top: 0.7rem;
    padding-bottom: .7rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: var(--primary-4);
    font-family: var(--font-mont);
    font-weight: 600;
    font-size: 16px;
    width: fit-content;
}


section#resource-tab .tab-content #nav-profile .rs-card ul {
    list-style: none;
    padding-left: 0.3rem;
    padding-top: 0.3rem;
    font-family: var(--font-lucida);
    font-size: 14px;
    color: #ffffff88;
}
section#resource-tab .tab-content #nav-profile .rs-card ul li {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-weight: 400;
}

section#resource-tab #nav-tab::-webkit-scrollbar {
    display: none;
}

#nav-resource .resource-group:first-child {
    display: none;
}

.xl-only .need-help {
    background-color: var(--primary-5);
    padding: 1.3rem;
    margin-right: 3rem;
    margin-top: 2rem;
    height: 195px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}
.need-help .font-lucida {
    opacity: 50%;
    padding-top: 1rem;
    color: #ccc;
}
.need-help .contact {
    width: 100%;
    border: none;
    outline: none;
    background-color: var(--primary-2);
    color: #ccc;
    font-weight: 500;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5px;
    font-family: var(--font-mont);
    margin-top: auto;
}
#nav-profile .endpoint-tag,
#nav-home .endpoint-tag ,
#nav-contact .endpoint-tag {
    background-color: var(--primary-4);
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 5px;
    width: fit-content;
    margin-bottom: 0.8rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    color: #ccc;
    font-family: var(--font-lucida);
}

#nav-contact .like-form {
    width: 100%;
}

@media (width > 1399px) {
    .res-n-form .dev-res {
        transform: translateY(-30px);
    }
}

@media (width > 1024px) {
    #api-detail .overview-n-form,
    .res-n-form {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    #nav-contact .like-form {
        padding-left: 4rem;
        padding-right: 0;
    }
}

@media (width < 1399.98px) {
    #api-detail .overview-n-form .like-form {
        display: none;
    }
    #api-detail .overview-n-form {
        padding-bottom: 1rem;
    }
}

@media (width > 1400px) {
    section#api-detail .overview-n-form {
        display: flex;
        justify-content: space-between;
    }
    section#api-detail .overview{
        width: 720px;
    }
    #api-detail .res-n-form .like-form {
        display: none;
    }
    #api-detail .res-n-form .dev-res {
        /* transform: translateY(-20%); */
        width: 720px;
    }
    section#resource-tab #nav-tab {
        width: 100%;
    }
    section#resource-tab #nav-tab .nav-link {
        flex: 1;
    }
    section#resource-tab .tab-content #nav-profile > .d-flex {
        column-gap: 1.5rem;
    }
}

@media (1025px < width < 1399px) {
    #api-detail .res-n-form .dev-res {
        width: max(720px, 60%);
    }
    section#resource-tab .overview-n-form .overview {
        width: 100%;
    }
    section#resource-tab #nav-tab {
        width: 100%;
    }
    section#resource-tab #nav-tab .nav-link {
        flex: 1;
    }
    section#resource-tab .tab-content #nav-profile > .d-flex {
        column-gap: 1.5rem;
    }
}

#nav-tab::-webkit-scrollbar {
    display: none;
}

@media (769px < width < 1024px) {
    
    section#resource-tab #nav-tab {
        max-width: 100%;
        overflow-x: scroll;
    }
    section#resource-tab #nav-tab .nav-link {
        width: 300px;
    }
    section#resource-tab .tab-content #nav-profile > .d-flex {
        column-gap: 1.5rem;
    }
}

@media (width < 768px) {
    section#resource-tab .tab-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    section#resource-tab  .nav-tabs {
        overflow-x: scroll;
    }
    section#resource-tab #nav-tab .nav-link {
        width: 50%;
    }
    
    section#api-detail .detail-hero > .font-lucida,
    section#api-detail .grid-center,  
    section#api-detail .overview-n-form .overview  {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    section#resource-tab .tab-content #nav-profile > .d-flex {
        column-gap: 1.5rem;
    }

    div.dev-res .fsxl20 {
        font-size: 16px;
    }
    div.res-n-form {
        row-gap: 2rem;
        align-items: center;
    }
    div.dev-res .fsxl-m16 {
        font-size: 14px;
    }
}