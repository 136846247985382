/* Styles */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Roboto+Mono:wght@100;200;300;400;500;600&display=swap');
@import url("https://fonts.cdnfonts.com/css/lucida-console");

main {
    background-color: #000B0E;
}
main {
    /* max-width: 1500px; */
    margin: auto;
}

:root {
  --primary-1: #0d9b9c;
  --primary-2: #059b9a;
  --primary-3: #0d9b9c;
  --primary-3-25: rgba(13, 155, 156, 0.25);
  --primary-4: rgba(13, 155, 156, 0.15);
  --primary-5: #001a21;
  --primary-6: #0a4958;
  --large-desktop-l: 1400px;
  --desktop-u: 1399px;
  --desktop-l: 1025px;
  --small-desktop-u: 1024px;
  --small-desktop-l: 769px;
  --tablet-u: 768px;
  --tablet-l: 481px;
  --mobile-u: 480px;
  --mobile-l: 320px;
  --font-mont: 'Montserrat', sans-serif;
  --font-lucida: 'Roboto Mono', monospace;
  --fsm96: 96px;
  --fsm32: 32px;
  --fsm36: 36px;
  --fsm24: 24px; 
  --fsm20: 20px;
  --fsm18: 18px;
  --fsm16: 16px;
  --fsm14: 14px;
  --fsl16: 16px;
  --fsl14: 14px;
}

a.text-cc {
    color: #ccc !important;
}

@media (width < 1400px) {
    .xl-only {
        display: none;
    }
}
.br-5 {
    border-radius: 5px;
}

.text-primary-1 {
    color: var(--primary-1);
}
.text-primary-2 {
    color: var(--primary-2);
}
.text-primary-3 {
    color: var(--primary-3);
}
.bg-primary-3 {
    background-color: var(--primary-3);
}
.text-primary-3.imp {
    color: var(--primary-3) !important;
}
.text-primary-4 {
    color: var(--primary-4);
}
.text-primary-5 {
    color: var(--primary-5);
}

.fsxl96 {
    font-size: 96px;
    line-height: 117px;
}
.fsxl72 {
    font-size: 72px;
    line-height: 87px;
}
.fsxl64 {
    font-size: 64px;
    line-height: 78px;
}
.fsxl48 {
    font-size: 48px;
    line-height: 59px;
}
.fsxl40 {
    font-size: 40px;
    line-height: 48px;
}
.fsxl36 {
    font-size: 36px;
    line-height: 43px;
}
.fsxl32 {
    font-size: 32px;
    line-height: 39px;
}
.fsxl24 {
    font-size: 24px;
    line-height: 29px;
}
.fsxl20 {
    font-size: 20px;
    line-height: 24px;
}
.fsxl-m18 {
    font-size: 18px;
}
.fsxl-m16 {
    font-size: 16px;
    line-height: 150%;
}
.fsxl-m14 {
    font-size: 14px;
    line-height: 17px;
}
.fsxl16 {
    font-size: 16px;
    line-height: 22px;
}
.fsxl12 {
    font-size: 12px;
    line-height: 150%;
}
.fsxl-l20 {
    font-size: 20px;
    line-height: 150%;
}
.fsxl-l18 {
    font-size: 18px;
    line-height: 18px;
}
.fsxl-l16 {
    font-size: 16px;
    line-height: 16px;
}
.fsxl-l14 {
    font-size: 14px;
    line-height: 14px;
}

.capitalize {
    text-transform: capitalize;
}
.ml-auto {
    margin-left: auto;
}

body {
  padding: 0;
  margin: 0;
  background-color: #000;
}

.font-mont {
  font-family: 'Montserrat', sans-serif;
}

.font-lucida {
  font-family: 'Roboto Mono', monospace;
}

.debug {
  border: 1px solid red;
}

.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-400 {
    font-weight: 400;
}
.fw-300 {
    font-weight: 300;
}

.grid-center {
    display: grid;
    place-items: center;
}

.flex-center-all {
    display: flex;
    align-items: center;
    justify-content: center;
}
.o-08 {
    opacity: 0.8;
}
a {
    text-decoration: none !important;
    color: inherit !important;
}
.t-btn {
    background-color: transparent;
    border: none;
    outline: none;
}
.cdh {
    min-height: 810px;
}
.cdh.center {
    display: grid;
    place-items: center;
}
.rg-1 {
    row-gap: 1rem;
}
.search-tag {
    padding: 0.4rem 1rem;
    border-radius: 25px;
    background-color: var(--primary-5);
    color: var(--primary-3);
    font-family: var(--font-mont);
    display: flex;
    align-items: center;
    column-gap: 0.6rem;
}
.search-tag svg path {
    fill: var(--primary-3);
}

.text-tags{
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.text-tags .text-tag {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    padding: 0.2rem 0.8rem;
    border-radius: 25px;
    border: 1px solid #fff;
}
.text-tags.filled .text-tag {
    background-color: var(--primary-3-25);
    border: none;
    padding: 0.3rem 0.8rem;
}
.text-tags .text-tag .tag-image {
    width: 18px;
    height: 18px;
} 
.text-tags .text-tag .tag-text {
    font-family: var(--font-mont);
    font-size: 14px;
    font-weight: 400;
    color: #ccc;
}

.know-form {
    width: 415px;
    padding: 3rem 2.5rem;
    border-radius: 5px;
    background-color: #001A21;
}

.know-form form {
    display: flex;
    flex-direction: column;
    row-gap: 0.7rem;
}

.know-form form input {
    outline: none;
    font-style: 18px;
    border: none;
    font-family: var(--font-mont);
    height: 40px;
    border-radius: 5px;
    color: #ccc;
    padding-left: 0.5rem;
    transition: 0.3s ease;
}
.know-form form input:focus {
    border: 1.5px solid #0d9b9c;
}
.know-form form input:focus::placeholder {
    color: #ccc;
}
.know-form form input:not([type="submit"]) {
    background-color: rgba(13, 155, 156, 0.15);
}
.know-form form input:not([type="submit"])::placeholder {
    color: #ccc;
}
.know-form form input[type="submit"] {
    background-color: #059b9a;
    font-weight: 600;
}
.text-cc {
    color: #ccc;
}
.po-rel{
    position: relative;
}

.publish {
    opacity: 0.8;
    color: #ccc;
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}
.publish .value {
    font-weight: 500;
    opacity: 1;
}


form.form-hold {
    display: flex;
    flex-direction: column;
    row-gap: 0.9rem;
}
input.input-field {
    background-color: #0D9B9C26;
    outline: none;
    border-radius: 25px;
    color: #ccc;
    border: 3px solid transparent;
}
input.input-field::placeholder,
input.input-field:focus::placeholder {
    color: #fff;
}
input.input-field:focus {
    border: 3px solid #059B9A;
    color: #fff;
    font-weight: 600;
}
.submit-btn {
    background-color: transparent;
    color: var(--primary-3);
    border: 2px solid var(--primary-3);
    outline: none;
    border-radius: 25px;
    transition: 0.2s ease;
}
.submit-btn:hover {
    background-color: var(--primary-3);
    color: #000;
}
.m-fit-content {
    min-width: fit-content;
}
@media (width> 1399px) {
    .container {
        max-width: 1210px;
    }
}
@media (1025px < width < 1399px) {
    .fsxl96 {
        font-size: 64px;
        line-height: 78px;
    }
    .fsxl64 {
        font-size: 48px;
        line-height: 58px;
    }
    .fsxl48 {
        font-size: 40px;
        line-height: 48px;
    }
    .fsxl32 {
        font-size: 24px;
        line-height: 29px;
    }
    .fsxl24 {
        font-size: 20px;
        line-height: 24px;
    }
    .fsxl20 {
        font-size: 16px;
        line-height: 20px;
    }
    .fsxl-m14 {
        font-size: 12px;
        line-height: 15px;
    }
    .know-form form input {
        font-size: 12px;
    }
}

@media (769px < width < 1024px) {
    .cdh {
        min-height: 610px;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .fsxl96 {
        font-size: 40px;
        line-height: 49px;
    }
    .fsxl72 {
        font-size: 48px;
        line-height: 58px;
    }
    .fsxl64 {
        font-size: 40px;
        line-height: 48px;
    }
    .fsxl48 {
        font-size: 36px;
        line-height: 43px;
    }
    .fsxl40 {
        font-size: 32px;
        line-height: 40px;
    }
    .fsxl32 {
        font-size: 20px;
        line-height: 24px;
    }
    .fsxl24 {
        font-size: 16px;
        line-height: 19.5px;
    }
    .fsxl20 {
        font-size: 14px;
        line-height: 17px;
    }
    .fsxl-l16 {
        font-size: 14px;
        line-height: 14px;
    }

    .know-form form input {
        font-size: 12px;
    }
    .fsxl48 {
        font-size: 40px;
    }
}

@media (481px < width < 768px) {
    .fsxl96 {
        font-size: 40px;
        line-height: 48.7px;
    }
    .fsxl72 {
        font-size: 42px;
        line-height: 48px;
    }
    .fsxl64 {
        font-size: 32px;
        line-height: 39px;
    }
    .fsxl32 {
        font-size: 20px;
        line-height: 24px;
    }
    .fsxl24 {
        font-size: 16px;
        line-height: 19px;
    }
    .fsxl48{
        font-size: 40px;
        line-height: 48px;
    }
    .fsxl40 {
        font-size: 28px;
        line-height: 32px;
    }
    .fsxl20 {
        font-size: 12px;
        line-height: 14px;
    }
}

@media (width < 481px) {
    .fsxl96 {
        font-size: 40px;
        line-height: 48.7px;
    }
    .fsxl72 {
        font-size: 24px;
        line-height: 29px;
    }
    .fsxl64 {
        font-size: 32px;
        line-height: 39px;
    }
    .fsxl40 {
        font-size: 16px;
        line-height: 19px;
    }
    .fsxl32 {
        font-size: 20px;
        line-height: 24px;
    }
    .fsxl24 {
        font-size: 14px;
        line-height: 16px;
    }
    .fsxl20 {
        font-size: 12px;
        line-height: 14px;
    }
    .fsxl48{
        font-size: 32px;
    }
    .fsxl-l20 {
        font-size: 14px;
    }
    .fsxl-m14 {
        font-size: 12px;
        line-height: 18px;
    }
}

@media (width < 768px) {
    .s-flex-column {
        flex-direction: column;
    }
}


div.loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-top: 2px solid var(--primary-1);
    animation: rotating 1s infinite;
}
@keyframes rotating {
    from {
        rotate: 0;
    }
    to {
        rotate: 360deg;
    }
}