section#upload {
    background-image: url('https://6637851.fs1.hubspotusercontent-na1.net/hubfs/6637851/Api%20Direct%20Version%202%20Resources/ReactApiImg/details-hero-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

section#upload div.text-content {
    width: 710px;
    padding-top: 4rem;
}
section#upload div.img-content { 
    background-image: url('https://6637851.fs1.hubspotusercontent-na1.net/hubfs/6637851/Api%20Direct%20Version%202%20Resources/ReactApiImg/form-r-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 330px;
    display: flex;
    height: 515px;
}

div#form-tabs {
    padding-bottom: 3rem;
}

div#form-tabs nav {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

div#form-tabs .nav {
    flex-wrap: nowrap;
}
div#form-tabs  .nav-tabs {
    border-width: 1.5px;
    border-color: #ccc;
    max-width: 100vw;
}
div#form-tabs #nav-tab .nav-link {
    /* min-width: 33.33%;
    width: fit-content; */
    font-family: var(--font-mont);
    color: #ccc;
    outline: none;
    border: none;
    background-color: transparent;
    transition: 0.2s ease;
    padding-bottom: 1rem;
    line-height: 54px;
}

#upload #nav-tab .nav-link.active {
    font-weight: 600;
}
.adv-que{
    text-align: left;
    padding-left: 0.5rem;
    font-family: var(--font-mont);
    color: #ccc;
    padding-bottom: 1rem;
    margin-top: 2rem;
}
.cool-link {
    display: inline-block;
    color: #059B9C;
    text-decoration: none;
    padding-left: 2.5rem;
    margin-bottom: 2rem;
}
.descr{
    margin-left: 1rem;
}

.cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: #059B9C;
    transition: width .3s;
}

.cool-link:hover::after {
    width: 100%;
    transition: width .3s;
    margin-bottom: 3px;
}
a.contact {
    text-align: center;
}
.margin-ryt{
    margin-right: 14%;
}
.red-req{
    color: #ff0000;
}

.fsxl-l18{
    line-height: 26px;
}
.line-height{
    line-height: 110px;
}

@media (width > 1299px) {
    #upload .img-content {
        transform: translateX(-5rem);
    }
}


@media (width < 1399px) {
    div#form-tabs #nav-tab .nav-link.active {
        border-bottom: 2px solid #059B9C;
    }
    div#form-tabs  .nav-tabs {
        border-width: 1.5px;
        border-color: #ccc;
        max-width: 100vw;
    }
    div#form-tabs .title {
        display: none;
    }
}

div#form-tabs .tab-content {
    margin-top: 2rem;
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    background-color: var(--primary-5);
    font-family: var(--font-mont);
    color: #ccc;
}

.svg{
    cursor: pointer;
}

div#form-tabs .section-title {
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #fff;
    margin-bottom: 1.4rem;
}

div#form-tabs #nav-basic .q-hold input::placeholder {
    color: #fff;
}

div#form-tabs .q-grid {
    row-gap: 1.3rem;
}
@media (1025px < width ) {
    div#form-tabs #nav-basic .q-hold {
        width: 44%;
    }
}
@media (769px < width < 1024px) {
    div#form-tabs #nav-basic .q-hold {
        width: 46%;
    }
}
div#form-tabs #nav-resource > .d-flex {
    row-gap: 1rem;
}
div#form-tabs #nav-resource .form-control,
div#form-tabs #nav-contact .form-control {
    width: min(430px, 100%);
}
div#form-tabs .q-hold label {
    font-size: 14px;
    margin-bottom: 16px;
    height: 22px;
}
div#form-tabs .q-hold .form-control,
div#form-tabs .q-hold .form-check-input {
    background-color: var(--primary-4);
    outline: none;
    color: #ccc;
    transition: 0.2s ease;
}
div#form-tabs .q-hold .form-control {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

div#form-tabs .q-hold .form-check-input:focus-within,
div#form-tabs .q-hold .form-check-input:active {
    border-color: #059B9C;
    box-shadow: none;
}
div#form-tabs .q-hold .form-check-input {
    border-color: #ccc;
}

div#form-tabs .q-hold .form-control {
    border-color: transparent;
}
div#form-tabs .q-hold .form-control:focus-within {
    border-color: #059B9C;
    box-shadow: none;
}
div#form-tabs .q-hold select.form-control:focus-within {
    color: black;
}

div#form-tabs .q-hold .form-control option {
    padding-top: 1rem;
}


section#upload .form-btn {
    font-weight: 500;
    color: #ccc;
    border: none;
    outline: none;
    padding: 0.4rem 1.5rem;
    border-radius: 5px;
}
section#upload .form-btn.next {
    background-color: var(--primary-3);
}
section#upload .form-btn.back {
    background-color: rgba(13, 155, 156, 0.5);
}

#nav-tabContent {
    border-radius: 5px;
}

.why-api li{
    margin-bottom: 1rem;
    list-style: none;
}

@media (width > 1400px) {
    section#upload .form-wrap #form-tabs {
        display: flex;
        align-items: flex-start;
    }
    section#upload .form-wrap #form-tabs nav .nav {
        flex-direction: column;
        width: 400px;
        margin-top: 4rem;
    }
    .not-xl {
        display: none;
    }
    div#form-tabs #nav-tab .nav-link {
       border-left: 1px solid #fff;
       text-align: left;
       padding-left: 3rem;
    }
    div#form-tabs #nav-tab .nav-link.active {
        border-color: #059B9C;
        border-width: 3px;
    }
    div#form-tabs  .nav-tabs {
        border: none;
    }
    #nav-resource-tab,
    #nav-contact-tab {
        padding-top: 2rem;
    }
}

@media (1025px < width < 1399px) {
    div#form-tabs #nav-tab .nav-link {
        width: 33.33%;
    }
}

@media (769px < width < 1024px) {
    div#form-tabs #nav-tab .nav-link {
        width: 33.33%;
    }
}

@media (481px < width < 768px) {
    div#form-tabs #nav-tab .nav-link {
        width: 33.33%;
    }
}

@media (width < 768px) {
    div#form-tabs .tab-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    section#upload div.text-content {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    section#upload div.img-content {
        display: none;
    }
    div#form-tabs  .nav-tabs {
        overflow-x: scroll;
    }
    
}

@media (482px < width < 768px) {
    div#form-tabs #nav-basic .q-hold {
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media (width < 481px) {
    div#form-tabs #nav-basic .q-hold {
        width: 100%;
    }
    section#upload .text-content .fsxl24 {
        font-size: 17px;
    }
    section#upload .text-content .font-lucida.fsxl-l18 {
        font-size: 13px;
        line-height: 13px;
    }
    section#upload .title .fsxl24 {
        font-size: 24px;
    }
    section#upload .title .fsxl-l18 {
        font-size: 12px;
    }
}

/* section#upload .resource-group {
    display: none;
}

section#upload .resource-group:last-of-type {
    display: block !important;
} */